<template>
  <div class="log-table">
    <el-table
      border
      :data="list"
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
    >
      <el-table-column prop="tempId" label="ID" align="center" width="50px"></el-table-column>
      <el-table-column prop="adminName" label="操作人" align="center" width="128px"></el-table-column>
      <el-table-column prop="content" align="left" label="记录" header-align="center"></el-table-column>
      <el-table-column prop="createdAt" label="时间" align="center" width="170px" ></el-table-column>
    </el-table>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        default: () => []
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>
