import API from "@/api";

export default {
  getList: params => {
    return API.requestGet({
      url: "operator_logs/",
      params,
    });
  },
};
