<template>
  <div class="form">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-input v-model.trim="search.content" class="account" clearable>
          <template slot="prepend">内容</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          class="time"
          v-model="date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        search: {
          content: ''
        },
        date: []
      };
    },
    methods: {
      searchList() {
        let [beginTime = '', endTime = ''] = this.date || [];
        let param = Object.assign({ beginTime, endTime }, this.search);
        this.$emit('search', param);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .form {
    text-align: left;
  }
</style>
